import { createRouter, createWebHistory } from 'vue-router';
import CourseHome from '/@/pages/CourseHome.vue';
import DefaultView from '/@/layouts/DefaultView.vue';

const routes = [
  {
    path: '/',
    component: DefaultView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'student-dashboard',
        component: () => import('/@/pages/StudentDashboard.vue'),
        meta: { requiresAuth: true },
        alias: '/:pathMatch(.*)*',
      },
      {
        path: 'courses/:course',
        name: 'course-dashboard',
        component: () => import('/@/pages/StudentInterface.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/accessibility',
        name: 'accessibility',
        component: () => import('/@/pages/AccessibilityStatement.vue'),
      },
    ],
  },
  {
    path: '/play/courses/:course',
    name: 'course-home',
    component: CourseHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/play/courses/:course/intro',
    name: 'courses-intro',
    component: () => import('/@/pages/CoursesIntro.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/play/courses/:course/lessons/:lesson',
    name: 'skill-lesson',
    component: () => import('/@/pages/LessonPreview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/play/course/:course/surveys/:survey/questionnaires/:questionnaire',
    name: 'onboarding',
    component: () => import('/@/pages/OnboardingPreview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/play/projects/:project/stages/:stage',
    name: 'skill-project',
    component: () => import('/@/pages/ProjectPreview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/play/surveys/:survey/questionnaires/:questionnaire',
    name: 'survey-preview',
    component: () => import('/@/pages/SurveyPreview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/decisiongame',
    name: 'decision-game',
    component: () =>
      import('../../../src/apps/slideshowGame/DecisionMakingGame.vue'),
    meta: { requiresAuth: true },
  },
];

export const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash, top: 100, behavior: 'smooth' };
    } else {
      return { top: 0, left: 0 };
    }
  },
  history: createWebHistory(),
  routes,
});
